<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
      >
        <stats-card>
          <template slot="header">
            <i>
              <img src="@/assets/img/noimage.jpeg" height="30" width="=30" />
            </i>
          </template>

          <template slot="content">
            <h4 class="title"><b>CEFUROXIMA</b></h4>
            <h5 class="title"><b>750mg</b> | Apresentação: <b>Injetável</b></h5>
            <h5 class="title">Validade: <b>31/12/2024</b> | Lote: <b>999999</b></h5>
            <h5 class="title">Medicamento refrigerado? <b>Não</b></h5>
            <!-- <h5 class="title">Nome hospital - Cidade/UF</h5> -->
          </template>

          <template slot="footer">
            <div class="md-layout-item md-size-30">
              <h4>Reservado: <b>1.000</b></h4>
            </div>
            <div class="md-layout-item md-size-30">
              <h4>Disponível: <b>4.000</b></h4>
            </div>
            <div class="md-layout-item md-size-22">
              <md-field>
                <label>Quantidade</label>
                <md-input
                  v-estoque="quantity"
                  type="number"
                  valuenow="0"
                  valuemin="0"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-25">
              <md-button class="md-raised md-success">Add</md-button>
            </div>
          </template>
        </stats-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
      >
        <stats-card data-background-color="blue">
          <template slot="header">
            <i>
              <img src="@/assets/img/noimage.jpeg" height="30" width="=30" />
            </i>
          </template>

          <template slot="content">
            <h4 class="title"><b>Imunoglobulina RhO D</b></h4>
            <h5 class="title"><b>Unidade</b> | Apresentação: <b>Injetável</b></h5>
            <h5 class="title">Validade: <b>31/12/2024</b> | Lote: <b>999999</b></h5>
            <h5 class="title">Medicamento refrigerado? <b>Sim</b></h5>
            <!-- <h5 class="title">Nome hospital - Cidade/UF</h5> -->
          </template>

          <template slot="footer">
            <div class="md-layout-item md-size-30">
              <h4>Reservado: <b>10</b></h4>
            </div>
            <div class="md-layout-item md-size-30">
              <h4>Disponível: <b>90</b></h4>
            </div>
            <div class="md-layout-item md-size-22">
              <md-field>
                <label>Quantidade</label>
                <md-input
                  v-estoque="quantity"
                  type="number"
                  valuenow="0"
                  valuemin="0"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-25">
              <md-button class="md-raised md-success">Add</md-button>
            </div>
          </template>
        </stats-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
      >
        <stats-card data-background-color="blue">
          <template slot="header">
            <i>
              <img src="@/assets/img/noimage.jpeg" height="30" width="=30" />
            </i>
          </template>

          <template slot="content">
            <h4 class="title"><b>Amiodarona</b></h4>
            <h5 class="title"><b>50mg/ml 3ml</b> | Apresentação: <b>Injetável</b></h5>
            <h5 class="title">Validade: <b>31/12/2024</b> | Lote: <b>999999</b></h5>
            <h5 class="title">Medicamento refrigerado? <b>Não</b></h5>
            <!-- <h5 class="title">Nome hospital - Cidade/UF</h5> -->
          </template>

          <template slot="footer">
            <div class="md-layout-item md-size-30">
              <h4>Reservado: <b>500</b></h4>
            </div>
            <div class="md-layout-item md-size-30">
              <h4>Disponível: <b>1.500</b></h4>
            </div>
            <div class="md-layout-item md-size-22">
              <md-field>
                <label>Quantidade</label>
                <md-input
                  v-estoque="quantity"
                  type="number"
                  valuenow="0"
                  valuemin="0"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-25">
              <md-button class="md-raised md-success">Add</md-button>
            </div>
          </template>
        </stats-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
      >
        <stats-card data-background-color="blue">
          <template slot="header">
            <i>
              <img src="@/assets/img/noimage.jpeg" height="30" width="=30" />
            </i>
          </template>

          <template slot="content">
            <h4 class="title"><b>Soro Ringer lactato</b></h4>
            <h5 class="title"><b>500ml</b> | Apresentação: <b>Injetável</b></h5>
            <h5 class="title">Validade: <b>31/12/2024</b> | Lote: <b>999999</b></h5>
            <h5 class="title">Medicamento refrigerado? <b>Não</b></h5>
            <!-- <h5 class="title">Nome hospital - Cidade/UF</h5> -->
          </template>

          <template slot="footer">
            <div class="md-layout-item md-size-30">
              <h4>Reservado: <b>100</b></h4>
            </div>
            <div class="md-layout-item md-size-30">
              <h4>Disponível: <b>650</b></h4>
            </div>
            <div class="md-layout-item md-size-22">
              <md-field>
                <label>Quantidade</label>
                <md-input
                  v-estoque="quantity"
                  type="number"
                  valuenow="0"
                  valuemin="0"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-25">
              <md-button class="md-raised md-success">Add</md-button>
            </div>
          </template>
        </stats-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
      >
        <stats-card data-background-color="blue">
          <template slot="header">
            <i>
              <img src="@/assets/img/noimage.jpeg" height="30" width="=30" />
            </i>
          </template>

          <template slot="content">
            <h4 class="title"><b>Cateter duplo lúmen</b></h4>
            <h5 class="title"><b>Unidade</b> | Apresentação: <b>Und</b></h5>
            <h5 class="title">Validade: <b>31/12/2024</b> | Lote: <b>999999</b></h5>
            <h5 class="title">.</h5>
            <!-- <h5 class="title">Nome hospital - Cidade/UF</h5> -->
          </template>

          <template slot="footer">
            <div class="md-layout-item md-size-30">
              <h4>Reservado: <b>120</b></h4>
            </div>
            <div class="md-layout-item md-size-30">
              <h4>Disponível: <b>783</b></h4>
            </div>
            <div class="md-layout-item md-size-22">
              <md-field>
                <label>Quantidade</label>
                <md-input
                  v-estoque="quantity"
                  type="number"
                  valuenow="0"
                  valuemin="0"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-25">
              <md-button class="md-raised md-success">Add</md-button>
            </div>
          </template>
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
      >
        <stats-card data-background-color="blue">
          <template slot="header">
            <i>
              <img src="@/assets/img/noimage.jpeg" height="30" width="=30" />
            </i>
          </template>

          <template slot="content">
            <h4 class="title"><b>Cloridrato de tramadol</b></h4>
            <h5 class="title"><b>50mg/ml 1ml</b> | Apresentação: <b>Injetável</b></h5>
            <h5 class="title">Validade: <b>31/12/2024</b> | Lote: <b>999999</b></h5>
            <h5 class="title">Medicamento refrigerado? <b>Não</b></h5>
            <!-- <h5 class="title">Nome hospital - Cidade/UF</h5> -->
          </template>

          <template slot="footer">
            <div class="md-layout-item md-size-30">
              <h4>Reservado: <b>400</b></h4>
            </div>
            <div class="md-layout-item md-size-30">
              <h4>Disponível: <b>450</b></h4>
            </div>
            <div class="md-layout-item md-size-22">
              <md-field>
                <label>Quantidade</label>
                <md-input
                  v-estoque="quantity"
                  type="number"
                  valuenow="0"
                  valuemin="0"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-25">
              <md-button class="md-raised md-success">Add</md-button>
            </div>
          </template>
        </stats-card>
      </div>

    </div>
  </div>
</template>

<script>
import { StatsCard } from "@/components";

export default {
  components: {
    StatsCard,
  },
};
</script>
