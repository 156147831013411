import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import Dashboard from "@/pages/Dashboard.vue";
import Relatorio from "@/pages/Relatorio.vue";
import CadProduto from "@/pages/CadProduto.vue";
import CadEstoque from "@/pages/CadEstoque.vue";
import CadUsuario from "@/pages/CadUsuario.vue";
import Resumo from "@/pages/Resumo.vue";
import Notifications from "@/pages/Notifications.vue";

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Estoques",
        component: Dashboard,
      },
      {
        path: "relatorio",
        name: "Relatório",
        component: Relatorio,
      },
      {
        path: "resumo",
        name: "Resumo",
        component: Resumo,
      },
      {
        path: "notifications",
        name: "Alertas",
        component: Notifications,
      },
      {
        path: "cadEstoque",
        name: "Cadastro de estoque",
        component: CadEstoque,
      },
      {
        path: "cadProduto",
        name: "Cadastro de produtos",
        component: CadProduto,
      },
      {
        path: "cadUsuario",
        name: "Cadastro de usuário",
        component: CadUsuario,
      },
    ],
  },
];

export default routes;
