<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Estoques</p>
      </sidebar-link>
      <sidebar-link to="/resumo">
        <md-icon><svg-icon type="mdi" :path="path"></svg-icon></md-icon>
        <p>Resumo</p>
      </sidebar-link>
      <sidebar-link to="/relatorio">
        <md-icon>content_paste</md-icon>
        <p>Relatórios</p>
      </sidebar-link>
      <sidebar-link to="/notifications">
        <md-icon>notifications</md-icon>
        <p>Notificações</p>
      </sidebar-link>
      <sidebar-link to="/CadUsuario">
        <md-icon>add</md-icon>
        <p>Cadastro de usuários</p>
      </sidebar-link>
      <sidebar-link to="/CadEstoque">
        <md-icon>add</md-icon>
        <p>Cadastro de estoque</p>
      </sidebar-link>
      <sidebar-link to="/CadProduto">
        <md-icon>add</md-icon>
        <p>Cadastro de produtos</p>
      </sidebar-link>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content></dashboard-content>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCart } from "@mdi/js";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    SvgIcon,
  },
  data() {
    return {
      sidebarBackground: "green",
      path: mdiCart,
    };
  },
};
</script>
