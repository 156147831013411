<template>
  <div>
    <md-table v-model="medicamentos" table-header-color="blue">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Nome"> {{ item.nome }}</md-table-cell>
        <md-table-cell md-label="Qtde pedida"> {{ item.quantidade }}</md-table-cell>
        <md-table-cell md-label="Obs. do produto"> {{ item.obsProduto }}</md-table-cell>
        <md-table-cell md-label="Concentração"> {{ item.concentracao }}</md-table-cell>
        <md-table-cell md-label="Apresentação"> {{ item.apresentacao }}</md-table-cell>
        <md-table-cell md-label="Validade"> {{ item.validade }}</md-table-cell>
        <md-table-cell md-label="Lote"> {{ item.lote }}</md-table-cell>
        <md-table-cell md-label="Refrigerado?"> {{ item.refrigerado }}</md-table-cell>
        <md-table-cell md-label="Obs. Refrig."> {{ item.obsRefrig }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  name: "simple-table",
  data() {
    return {
      selected: [],
      medicamentos: [
        {
          nome:"CEFUROXIMA",
          quantidade:"1.000",
          obsProduto:"",
          concentracao:"750mg",
          apresentacao:"Injetável",
          validade:"31/12/2024",
          lote:"999999",
          refrigerado:"Não",
          obsRefrig:"",
        },
        {
          nome:"Imunoglobulina RhO D",
          quantidade:"10",
          obsProduto:"",
          concentracao:"Unidade",
          apresentacao:"Injetável",
          validade:"31/12/2024",
          lote:"999999",
          refrigerado:"Sim",
          obsRefrig:"",
        },
        {
          nome:"Amiodarona",
          quantidade:"500",
          obsProduto:"",
          concentracao:"50mg/ml 3ml",
          apresentacao:"Injetável",
          validade:"31/12/2024",
          lote:"999999",
          refrigerado:"Não",
          obsRefrig:"",
        },
        {
          nome:"Soro Ringer lactato",
          quantidade:"100",
          obsProduto:"",
          concentracao:"500ml",
          apresentacao:"Injetável",
          validade:"31/12/2024",
          lote:"999999",
          refrigerado:"Não",
          obsRefrig:"",
        },
        {
          nome:"Cateter duplo lúmen",
          quantidade:"120",
          obsProduto:"",
          concentracao:"Undidade",
          apresentacao:"Und",
          validade:"31/12/2024",
          lote:"999999",
          refrigerado:".",
          obsRefrig:"",
        },
      ],
    };
  },
};
</script>
