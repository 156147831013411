<template>
  <div class="content">
    <form>
      <md-card>
        <md-card-header :data-background-color="dataBackgroundColor">
          <h4 class="title">Cadastro de usuários</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Login</label>
                <md-input v-model="USU_LOGIN" disabled></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Senha</label>
                <md-input v-model="USU_SENHA" type="password"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Nível</label>
                <md-input v-model="USU_NIVEL" type=""></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Nome</label>
                <md-input v-model="USU_NOME" type="email"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-35">
              <md-field>
                <label>CNPJ Hospital</label>
                <md-input v-model="USU_CNPJHOSP" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>CEP</label>
                <md-input v-model="USU_CEP" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Estado</label>
                <md-input v-model="USU_ESTADO" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Cidade</label>
                <md-input v-model="USU_CIDADE" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Bairro</label>
                <md-input v-model="USU_CIDADE" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Rua</label>
                <md-input v-model="USU_RUA" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Número</label>
                <md-input v-model="USU_NUMERO" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100">
              <md-field>
                <label>Complemento</label>
                <md-input v-model="USU_COMP"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 text-right">
              <md-button class="md-raised md-success">Atualizar</md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
  </div>
</template>
<script>
export default {
  name: "edit-profile-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "blue",
    },
  },
  data() {
    return {
      USU_LOGIN: null,
      USU_SENHA: null,
      USU_NOME: null,
      USU_NIVEL: null,
      USU_CNPJHOSP: null,
      USU_ESTADO: null,
      USU_CIDADE: null,
      USU_CEP: null,
      USU_BAIRRO: null,
      USU_RUA: null,
      USU_NUMERO: null,
      USU_COMP: null,
    };
  },
};
</script>
<style></style>
