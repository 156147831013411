<template>
  <footer class="footer">
    <div class="container">
<!--
      <nav>
        <ul>
          <li>
            <a href="https://www.creative-tim.com">Creative Tim</a>
          </li>
          <li>
            <a href="https://creative-tim.com/presentation">About Us</a>
          </li>
          <li>
            <a href="http://blog.creative-tim.com">Blog</a>
          </li>
          <li>
            <a href="https://www.creative-tim.com/license">Licenses</a>
          </li>
        </ul>
      </nav>
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <a href="https://www.creative-tim.com/?ref=mdf-vuejs" target="_blank"
          >Creative Tim</a
        >
      </div>
-->
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
