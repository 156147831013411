<template>
  <div class="content">
    <form>
      <md-card>
        <md-card-header :data-background-color="dataBackgroundColor">
          <h4 class="title">Cadastro de estoque</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <!--
            <div class="md-layout-item md-small-size-100 md-size-15">
              <md-field>
                <label>Id Produto</label>
                <md-input v-model="ID_PRODUTO" type="text"></md-input>
              </md-field>
            </div>
            -->
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Nome do produto</label>
                <md-input v-model="PRO_NOME" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-field>
                <label>Quantidade disponível (unidade)</label>
                <md-input v-model="EST_QTDE" type="number"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-100">
              <md-field>
                <label>Observações do produto</label>
                <md-input v-model="PRO_NOME" type="text"></md-input>
              </md-field>
            </div>

            <!--
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Nome comercial</label>
                <md-input v-model="EST_NOMECOM" type="email"></md-input>
              </md-field>
            </div>
            -->

            <div class="md-layout-item md-small-size-100 md-size-40">
              <md-field>
                <label>Laboratório</label>
                <md-input v-model="EST_NOMELAB" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-30">
              <md-field>
                <label>Concentração</label>
                <md-input v-model="EST_DOSAGEM" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-30">
              <md-field>
                <label>Apresentação</label>
                <md-input v-model="EST_APRESENTA" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Validade</label>
                <md-input v-model="EST_VALIDADE" type="date"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <md-field>
                <label>Lote</label>
                <md-input v-model="EST_LOTE" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-40">
              <md-field>
                <label>Medicamento necessita refrigeração?</label>
                <md-input v-model="EST_REFIRG" type="checkbox"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field>
                <label>Observação (informar restrições)</label>
                <md-input v-model="EST_OBS2" type="text"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-size-100 text-right">
              <md-button class="md-raised md-success">Aplicar</md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
  </div>
</template>
<script>
export default {
  name: "edit-profile-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "blue",
    },
  },
  data() {
    return {
      code: null,
      ID_PRODUTO: null,
      EST_QTDE: null,
      EST_NOMECOM: null,
      EST_NOMELAB: null,
      EST_DOSAGEM: null,
      EST_APRESENTA: null,
      EST_CLASSE: null,
      EST_VALIDADE: null,
      EST_LOTE: null,
      EST_REFRIG: null,
      EST_NOMEIMG: null,
    };
  },
};
</script>
<style></style>
